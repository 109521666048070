<template>
  <ContainerCard :locationMsg="locationMsg" :width="90">
    <div class="options">
      <DemandRank
        @orderType="orderTypeHandle"
        @searchHandle="searchHandle"
      ></DemandRank>
      <el-button class="button" @click="toIssueDemand">
        <img src="../../assets/images/发布需求.png" alt="" />
        <span>发布需求</span>
      </el-button>
    </div>
    <div class="Demand">
      <Technology @techHandle="techHandle"></Technology>
      <div class="demandItems" v-loading="fullscreenLoading" v-empty="List">
        <div
          class="demandItem"
          v-for="(item, index) in List"
          :key="index"
          @click="toDemandDeatails(item.billid)"
        >
          <div class="jobName">
            <span class="name">{{ item.name }}</span>
            <span class="group"  v-for="(element, index) in item.techList">{{ element }}</span>
          </div>
          <!-- <div class="jobGroup">
            <div
              class="jobType"
              plain
              v-for="(element, index) in item.techList"
              :key="index"
              onClick="event.cancelBubble = true"
            >
              {{ element }}
            </div>
          </div> -->
          <div class="jobInfo">
            <div class="infoItem">
              <span class="infotitle">单位名称</span>
              <span class="infoContent">{{ item.company }}</span>
            </div>
            <div class="infoItem">
              <span class="infotitle">单位地址</span>
              <span class="infoContent">{{ item.address }}</span>
            </div>
            <div class="infoItem">
              <span class="infotitle">需求描述</span>
              <span class="infoContent">{{ item.memo }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="5"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="100"
      >
      </el-pagination>
    </div> -->
      <div class="pagination" v-if="totalPage > 1">
        <Pagination
          @changeIndex="setCurrentPage"
          :total="total"
          :totalPage="totalPage"
          :pageSize="params.pageSize"
        ></Pagination>
      </div>
    </div>
  </ContainerCard>
</template>
<script>
import Pagination from "../../components/Pagination.vue";
import Location from "../../components/Location.vue";
import Technology from "../../components/Info/Technology.vue";
import DemandRank from "../../components/Info/DemandRank.vue";
import ContainerCard from "../../components/newContainerCard.vue";
import { getPlainText } from "../../common/utils/tool";
import {
  getBizdemands,
  getBizdemandById,
  getNewestBizdemands,
  getHotBizdemands,
  publishDemand,
} from "../../api/demand/demand.js";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["techfieldList"]),
  },
  data() {
    return {
      List: [],
      locationMsg: [],
      total: 0,
      totalPage: 0,
      fullscreenLoading: false,
      params: {
        tech_field: "", //技术领域
        keyword: "",
        orderstr: "make_time", //click_num
        pageNo: 1,
        pageSize: 6,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    techHandle(val) {
      this.params.tech_field = val;
      this.getData();
    },
    searchHandle(val) {
      this.params.keyword = val;
      if (!val) {
        this.params.type = "";
      }
      this.getData();
    },
    orderTypeHandle(orderType) {
      this.params.orderstr = orderType;
      this.getData();
    },
    setCurrentPage(val) {
      this.params.pageNo = val;
      this.getData();
    },
    toIssueDemand() {
      this.$router.push("/demand/issueDemand");
    },
    async getData() {
      let that = this;
      that.fullscreenLoading = true;
      that.List = [];
      let res = await getBizdemands(that.params);

      that.List = res.data.items.map((item) => {
        let tmp = item;
        tmp.memo = getPlainText(getPlainText(item.memo));
        tmp.techList = [];
        Object.keys(that.techfieldList).forEach(function (key) {
          if (
            tmp?.tech_field &&
            tmp?.tech_field.split(",").indexOf(that.techfieldList[key]) > -1
          ) {
            tmp.techList.push(key);
          }
        });
        return tmp;
      });
      this.total = res.data.totalCount;
      console.log(res.data.totalPage);
      this.totalPage = res.data.totalPage;
      this.totalPage = Math.ceil(res.data.totalCount / this.params.pageSize);
      console.log(this.totalPage);
      that.fullscreenLoading = false;
    },
    toDemandDeatails(id) {
      this.$router.push({
        path: "/demand/demandDetails",
        query: {
          id: id,
        },
      });
    },
  },
  components: {
    Pagination,
    ContainerCard,
    Location,
    Technology,
    DemandRank,
  },
};
</script>
<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 118px;
}
.ContainerCard {
  padding: 10px;
}
.Technology,
.Rank {
  // margin-bottom: 20px;
  flex: 1;
  height: unset;
}
.Demand {
  height: 100%;
  width: 1260px;
  padding: 0px 30px;
}

.options {
  height: 80px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 30px;
  // margin: 0px 10px;
  .button {
    margin-left: 275px;
    width: 132px;
    height: 38px;
    background: #2885ff;
    border-radius: 6px;
    font-size: 16px;
    font-family: 思源黑体 CN Medium;
    font-weight: 500;
    color: #ffffff;
    padding: 0px;
    img {
      width: 16px;
      height: 14px;
      margin-right: 12px;
    }
  }
}
.demandItems {
  width: 1260px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 0px;
  // padding-top: 20px;
}
.demandItem {
  // width: 28%;
  // flex: 1;
  width: 630px;
  height: 158px;
  border-bottom: 1px solid #f2f2f2;
  box-sizing: border-box;
  cursor: pointer;
  // margin: 10px;
  padding: 30px 30px 30px 0px;
  background: #ffffff;
  // box-shadow: 0px 0px 3px 2px rgba(228, 228, 228, 0.5);
  &:nth-child(even) {
    border-right: 1px solid #f2f2f2;
  }
  &:nth-child(odd) {
    border-left: 0px;
    border-right: 0px;
    padding-left: 30px;
  }
  &:first-child {
    border-left: 0px;
    border-right: 0px;
    padding-left: 0px;
  }

  &:hover {
    border: none;
    z-index: 999;
    box-shadow: 0px 12px 40px rgba($color: #000000, $alpha: 0.12);
    padding-left: 30px;
  }
}
// .demandItem:nth-child(3n+0){
//   margin-right: 0px;
// }
.jobGroup {
  display: flex;
  flex-wrap: wrap;
  .jobType {
    margin: 10px 10px 10px 0;
    padding: 7px 10px;
    font-size: 14px;
    // font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-family: 思源黑体 CN Medium;
    font-weight: 400;
    color: #3e9cff;
    border-radius: 8px;
    border: 1px solid #3e9cff;
  }
}
.jobName {
  .name {
    font-size: 18px;
    font-weight: Medium;
    // font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-family: Medium;
    font-weight: 500;
    color: #101010;
    margin-right: 12px;
  }

  .group {
    font-family: Regular;
    color: #ffffff;
    font-size: 12px;
    line-height: 18px;
    background-color: #2885FF;
    padding: 3px 5px;
    border-radius: 3px;
    margin-right: 12px;
  }
}
.jobInfo {
  margin-top: 20px;
  // background: #f2f2f2;
  // border-radius: 8px;
  // padding: 20px;
  .infoItem {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    @mixin infotext {
      font-size: 12px;
      font-family: Regular;
      font-weight: 400;
      // line-height: 18px;
      display: inline-block;
    }
    .infotitle {
      display: inline-block;
      width: 56px;
      @include infotext;
      color: #8896a7;
      margin-right: 20px;
      height: 12px;
    }
    .infoContent {
      @include infotext;
      color: #404040;
      width: 502px;
      height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  // .infotitle {
  //   @include infotext;
  // }
  // .infoContent {
  //   @include infotext;
  //   width: 208px;
  // }
}

.block {
  text-align: center;
  margin: 30px 0;
}

@media screen and (max-width: 1100px) {
}

@media screen and (max-width: 768px) {
}
</style>