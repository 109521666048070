import request from '../../common/utils/request'

// 翻页查找需求对接
export function getBizdemands(data) {
  return request({
    url: "/itf/common/cjrh/demand/getBizdemands.json",
    method: "get",
    params: data
  });
}

// 根据Id查询需求对接详细信息
export function getBizdemandById(data) {
  return request({
    url: "/itf/common/cjrh/demand/getBizdemandById.json",
    method: "get",
    params: data
  });
}


// 查询最新n条需求
export function getNewestBizdemands(data) {
  return request({
    url: "/itf/common/cjrh/demand/getNewestBizdemands.json",
    method: "get",
    params: data
  });
}

// 查询最热n条需求
export function getHotBizdemands(data) {
  return request({
    url: "/itf/common/cjrh/demand/getHotBizdemands.json",
    method: "get",
    params: data
  });
}

// 发布需求
export function publishDemand(params) {
  return request({
    url: "/itf/common/cjrh/demand/publishDemand.json",
    method: "get",
    params: params
  });
}


