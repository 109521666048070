<template>
  <div class="Rank">
    <div class="sort">
      <el-tabs v-model="tabPane" style="width: 98px;">
        <el-tab-pane label="最新" name="make_time"></el-tab-pane>
        <el-tab-pane label="热门" name="click_num"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="search">
      <input class="input" v-model="input" placeholder="请输入您要搜索的内容"  />
      <span><img src="../../assets/images/找合作-搜索.png" class="search-icon" @click="searchHandle"/></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    defaultValue: {
      type: String,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      input: "",
      tabPane: "make_time",
    };
  },
  methods: {
    searchHandle() {
      this.$emit("searchHandle", this.input);
    },
  },
  created() {
    this.input = this.defaultValue;
  },
  watch: {
    tabPane(newVal, oldVal) {
      this.$emit("orderType", newVal);
    },
  },
};
</script>
<style scoped lang="scss">
.Rank {
  width: 756px;
  height: 108px;
//   padding: 24px 30px 0px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // flex-wrap: wrap;
  // height: 46px;
  .search {
    box-sizing: border-box;
    width: 360px;
    height: 38px;
    padding: 12px 0px 12px 12px;
    display: flex;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #2885ff;
    align-items: center;
    margin-bottom: 12px;
    &:hover {
      border: 2px solid #2885FF;
    }
    .input {
      width: 360px;
      flex: 1;
      border: none;
      outline: none;
      font-size: 18px;
      font-family: Regular;
      font-weight: 400;
      color: #999999;
      width: 100%;
      &::-moz-placeholder {
        font-size: 14px;
        color: #999999;
      }
      &::-webkit-input-placeholder {
        font-size: 14px;
        color: #999999;
      }
    }
    > span {
      height: 100%;
      display: flex;
      align-items: center;
      &:active {
        transform: scale(0.96);
      }
    }
    > span .search-icon {
      height: 12px;
      width: 12px;
      margin-right: 8px;
    }
    .search-button {
      cursor: pointer;
      box-sizing: border-box;
      padding: 1px;
      border-radius: 0px 6px 6px 0px;
      width: 72px;
      height: 38px;
      background-color: #2885ff;
      color: #ffffff;
      text-align: center;
      line-height: 38px;
      span {
        font-size: 16px;
      }
    }
  }
  .sort {
    // height: 34px;
    width: 186px;
    font-size: 16px;
    font-family: Regular;
    font-weight: 400;
    color: #101010;
    span {
      margin-left: 68px;
      margin-right: 17px;
    }
    ::v-deep .el-radio {
      margin-right: 17px;
    }
    ::v-deep .el-radio__label {
      color: #101010;
    }
    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      color: #101010;
      border-color: #14569f;
      background: #ffffff;
    }
    ::v-deep .el-radio__inner::after {
      background-color: #14569f;
      height: 7px;
      width: 7px;
    }
    ::v-deep .el-tabs__nav-wrap::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.125rem;
        background-color: #E4E7ED;
        z-index: 1;
    }
    ::v-deep .el-tabs__item {
        color: #8896A7;
        font-size: 14px;
        
    }
    ::v-deep .el-tabs__active-bar {
        background-color: #2885FF;
    }
    ::v-deep .is-active {
        color: #101010;
        font-size: 14px;
    }
    ::v-deep .el-tabs__nav-wrap::after {
        background-color: #FFFFFF;
    }
  }
}

</style>